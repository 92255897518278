import { render, staticRenderFns } from "./Inactive.vue?vue&type=template&id=3a601e9a"
import script from "./Inactive.vue?vue&type=script&lang=js"
export * from "./Inactive.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports